import styled from "styled-components"
import mediaqueries from "../mediaqueries"

export default styled.div`
  display:flex;
  box-sizing: border-box;
  align-items: ${props =>
    `${props.centerVertically ? "center" : "flex-start"};`};
  flex: 1 1 auto;
  flex-basis: ${props => `${(100 * (props.xs || 12)) / 12}%`};
  max-width: ${props => `${(100 * (props.xs || 12)) / 12}%`};

  ${mediaqueries.sm`flex-basis: ${props =>
    `${(100 * (props.sm || 12)) / 12}%`};`}
  ${mediaqueries.md`flex-basis: ${props =>
    `${(100 * (props.md || 12)) / 12}%`};`}
  ${mediaqueries.lg`flex-basis: ${props =>
    `${(100 * (props.lg || 12)) / 12}%`};`}
`
