import styled from "styled-components"

export default styled.div`
  margin: 0 auto;
  max-width: ${props => `${props.maxWidth || 500}px`};
`

export const ContainerLeft = styled.div`
  margin-left: 0;
  max-width: ${props => `${props.maxWidth || 500}px`};
`
