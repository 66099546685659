import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  display: flex;
  color: white;
  padding: 5px 30px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  background-color: black;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: 0.2s all ease;
  }
`

export default ({ children, isSubmitting }) => {
  return <StyledButton disabled={isSubmitting}>{children}</StyledButton>
}
