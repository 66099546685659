// import React from "react"
import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

// export default IndexPage

import React from "react"
import styled from "styled-components"
import Page from "../components/Page"
import SEO from "../components/seo"
import Logo from "../components/Logo"
import Button from "../components/Button"
import { Input, TextArea } from "../components/Inputs"
import P from "../components/styles/P"
import Container, { ContainerLeft } from "../components/styles/Container"
import Spacer from "../components/styles/Spacer"
import Center from "../components/styles/Center"
import Flex from "../components/styles/Flex"
import Fade from "../components/styles/Fade"
import Span from "../components/styles/Span"
import Column from "../components/styles/Column"
import Row from "../components/styles/Row"
import Image from "../components/styles/Image"
import FullHeightSection from "../components/styles/FullHeightSection"
import THEME from "../theme"
import FormContainer from "../components/styles/FormContainer"
import StyledLink from "../components/styles/StyledLink"
import contactImage from "../images/contact.png"

export default class Home extends React.Component {
  // state = {
  //   name: "",
  //   email: "",
  //   project: "",
  // }

  constructor(props) {
    super(props)
    this.contactPage = React.createRef()
  }

  scrollToContact = () => {
    window.scrollTo({
      top: this.contactPage.current.offsetTop,
      behavior: "smooth",
    })
  }

  // handleSubmit = async e => {
  //   e.preventDefault()
  //   const { name, email, project } = this.state
  //   this.setState({ isLoading: true })
  //   const res = await fetch("/.netlify/functions/contact", {
  //     body: JSON.stringify({ email, name, project }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //   })
  //   if (res.error || !res.ok) {
  //     this.setState({ isLoading: false, hasSubmitError: true })
  //   } else {
  //     this.setState({ isLoading: false, isSubmitted: true })
  //     this.resetForm()
  //   }
  //   setTimeout(() => {
  //     this.setState({ isSubmitted: false, hasSubmitError: false })
  //   }, 2500)
  // }

  // resetForm = () => {
  //   this.setState({ name: "", email: "", project: "" })
  // }

  // handleFormUpdate = e => {
  //   this.setState({
  //     [e.target.id]: e.target.value,
  //   })
  // }

  render() {
    // const {
    // name,
    // email,
    // project,
    // isLoading,
    // isSubmitted,
    // hasSubmitError,
    // } = this.state
    return (
      <Page>
        <SEO title="Home" />
        <FullHeightSection>
          <Center>
            <Logo size={200} />
            <Container maxWidth={230}>
              <Spacer size={40} />
              <P alignment={P.ALIGNMENT.CENTER}>Hi, I'm Matthew!</P>
              <P alignment={P.ALIGNMENT.CENTER}>
                I design sites, ship code, and tell dad jokes.{" "}
              </P>
              <P alignment={P.ALIGNMENT.CENTER}>
                Sometimes I write about code{" "}
                <StyledLink as={Link} to="/posts">
                  here
                </StyledLink>
                .
              </P>
              <P alignment={P.ALIGNMENT.CENTER}>
                I've done projects for{" "}
                <StyledLink
                  href="https://www.newrelic.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  New Relic
                </StyledLink>
                ,{" "}
                <StyledLink
                  href="https://www.sourceclear.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SourceClear
                </StyledLink>
                ,{" "}
                <StyledLink
                  href="https://auraframes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Aura Frames
                </StyledLink>
                , and{" "}
                <StyledLink
                  href="https://www.turo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Turo
                </StyledLink>
                .
              </P>
              <P alignment={P.ALIGNMENT.CENTER}>Need help with your website?</P>
              <P
                alignment={P.ALIGNMENT.CENTER}
                onClick={this.scrollToContact}
                style={{ cursor: "pointer" }}
              >
                Let's chat!
              </P>
              <P style={{ textAlign: "center" }}>
                hello<code>@</code>matthewknudsen.com
              </P>
            </Container>
          </Center>
        </FullHeightSection>
        <Spacer size={30} />
        <FullHeightSection ref={this.contactPage}>
          <Row>
            <Column xs={12} md={6} lg={6}>
              <FormContainer>
                <P style={{ paddingTop: 30 }} size={24}>
                  Fancy meeting you here!
                </P>
                <Spacer size={30} />

                <form
                  name="contact-form"
                  // action="/demos/alegre/thank-you"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  // className={className}
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact-form" />
                  <Input
                    label="Name"
                    type="text"
                    id="name"
                    name="name"
                    // onChange={this.handleFormUpdate}
                    // value={name}
                  />
                  <Input
                    label="Email Address"
                    type="email"
                    id="email"
                    name="email"
                    // onChange={this.handleFormUpdate}
                    // value={email}
                  />
                  <TextArea
                    label="Brief Description"
                    type="textarea"
                    id="project"
                    name="project"
                    // onChange={this.handleFormUpdate}
                    // value={project}
                  />

                  <Spacer size={16} />
                  <Flex>
                    <Button>Submit</Button>
                    {/* <Fade in={isSubmitted}>
                      <Span
                        color={THEME.palette.primary.dark}
                        style={{ paddingLeft: "16px" }}
                      >
                        Got it! Talk soon ✌️
                      </Span>
                    </Fade> */}
                  </Flex>

                  {/* <Row alignItems="center" justifyContent="center" flexWrap="wrap">
                    <Column className="xs:pt-4 lg:pr-4" xs={12} lg={3}>
                      <StyledInput type="text" name="first-name" id="first-name" required placeholder="First name" />
                    </Column>
                    <Column className="xs:pt-4 lg:pr-4" xs={12} lg={3}>
                      <StyledInput type="text" name="email" id="email" placeholder="Email" required />
                    </Column>
                    <Column className="xs:pt-4" xs={12} lg={3}>
                      <SecondaryButton className="px-8 py-4" color="white" as="button" type="submit">{buttonText}</SecondaryButton>
                    </Column>
                  </Row> */}
                </form>

                {/* <form onSubmit={this.handleSubmit}>
                  <Input
                    label="Name"
                    type="text"
                    id="name"
                    onChange={this.handleFormUpdate}
                    value={name}
                  />
                  <Input
                    label="Email Address"
                    type="email"
                    id="email"
                    onChange={this.handleFormUpdate}
                    value={email}
                  />
                  <TextArea
                    label="Brief Description"
                    type="textarea"
                    id="project"
                    onChange={this.handleFormUpdate}
                    value={project}
                  />
                  <Spacer size={16} />
                  <Flex>
                    <Button isSubmitting={isLoading}>Submit</Button>
                    <Fade in={isSubmitted}>
                      <Span
                        color={THEME.palette.primary.dark}
                        style={{ paddingLeft: "16px" }}
                      >
                        Got it! Talk soon ✌️
                      </Span>
                    </Fade>
                  </Flex>
                  <Fade in={hasSubmitError} exit={!hasSubmitError}>
                    <P color={THEME.palette.error.main}>
                      Ohhh rats. Something went wrong. Try again?
                    </P>
                  </Fade>
                </form> */}
              </FormContainer>
            </Column>
            <Column xs={12} md={6} lg={6} centerVertically>
              <Container maxWidth={400}>
                <Spacer xs={50} />
                <Image src={contactImage} alt="contact me!" rotate={10} />
              </Container>
            </Column>
          </Row>
        </FullHeightSection>
        <Spacer size={500} />
      </Page>
    )
  }
}
